import React, { useState, useEffect } from "react";
import "./Navbar.css";
import Logo from "../../logo.png";
import { FaWhatsapp, FaTelegramPlane, FaPhoneAlt } from "react-icons/fa";
import ReactGA from "react-ga4"; // Подключаем Google Analytics

const Navbar = () => {
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const logGoal = (platform) => {
    if (platform === "telegram") {
      // Логирование цели в Yandex.Metrika для Telegram
      if (typeof window.ym === "function") {
        window.ym(98664452, "reachGoal", "go_tg");
      } else {
        console.error("Yandex.Metrika is not available.");
      }

      // Логирование цели в Google Analytics для Telegram
      ReactGA.event({
        category: "Social Clicks",
        action: "go_tg",
        label: "Moved to TG",
      });
    } else if (platform === "whatsapp") {
      // Логирование цели в Yandex.Metrika для WhatsApp
      if (typeof window.ym === "function") {
        window.ym(98664452, "reachGoal", "go_wa");
      } else {
        console.error("Yandex.Metrika is not available.");
      }

      // Логирование цели в Google Analytics для WhatsApp
      ReactGA.event({
        category: "Social Clicks",
        action: "go_wa",
        label: "Moved to WA",
      });
    }
  };

  return (
    <nav className={`nav-wrapper ${isScrolled ? "scrolled" : ""}`}>
      <div className="nav-content">
        {/* Логотип */}
        <a href="/">
          <img
            className={`logo ${isScrolled ? "scrolled-logo" : ""}`}
            src={Logo}
            alt="Logo"
          />
        </a>
        <div className={`contact-info ${isScrolled ? "scrolled-info" : ""}`}>
          {/* WhatsApp Icon */}
          <a
            href="https://wa.me/message/5RN54NMSW44HN1?src=website"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            onClick={() => logGoal("whatsapp")}
          >
            <FaWhatsapp />
          </a>

          {/* Telegram Icon */}
          <a
            href="https://t.me/MURAESTATE_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="icon"
            onClick={() => logGoal("telegram")}
          >
            <FaTelegramPlane />
          </a>

          {/* Phone Number */}
          <a href="tel:+74951086977" className="phone-number">
            +7 (495) 108-69-77
          </a>

          {/* Phone Icon */}
          <a href="tel:+74951086977" className="phone-icon">
            <FaPhoneAlt />
          </a>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
