import React, { useState } from "react";
import "./ApartmentQuiz.css";
import InputMask from "react-input-mask";
import ReactGA from 'react-ga4';

const ApartmentQuiz = ({ onComplete = () => {}, apartmentName }) => {
  const [currentStep, setCurrentStep] = useState(1);
  const [selectedOptions, setSelectedOptions] = useState([]); // Для хранения всех выбранных вариантов
  const [selectedOption, setSelectedOption] = useState("");
  const [phone, setPhone] = useState("");
  const [isConsentChecked, setIsConsentChecked] = useState(false);

  const totalSteps = 4; // Общее количество шагов

  // Функция для получения clientId из Google Analytics
  const getClientId = () => {
    return new Promise((resolve, reject) => {
      if (window.gtag) {
        window.gtag("get", "G-XXXXXXXXXX", "client_id", (clientId) => {
          if (clientId) resolve(clientId);
          else reject("Client ID not available");
        });
      } else {
        reject("gtag.js not found");
      }
    });
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  const handleNext = () => {
    if (selectedOption) {
      setSelectedOptions((prev) => [...prev, selectedOption]); // Сохраняем выбранный вариант
      setSelectedOption(""); // Очищаем текущий выбор
      setCurrentStep((prev) => prev + 1);
    }
  };

  const handleSubmit = async () => {
    try {
      const additionalInfo = selectedOptions.join(", "); // Формируем строку из выбранных опций

      // Получаем clientId
      const clientId = await getClientId();

      const data = {
        phoneNumber: phone,
        apartmentName: apartmentName,
        additionalInfo: additionalInfo,
        clientId: clientId, // Используем полученный clientId
      };

      const response = await fetch("https://muraestate.ru/api/amocrm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log("Данные успешно отправлены:", data);

        // Логируем успешное событие в Yandex.Метрике (с проверкой на доступность)
        if (typeof window.ym === "function") {
          window.ym(98664452, "reachGoal", "phone_sent");
          console.log(`Yandex goal reached: phone_sent for ${apartmentName}`);
        } else {
          console.error("Yandex.Metrika is not available.");
        }

        // Логируем успешное событие в Google Analytics
        ReactGA.event({
          category: 'Residential complexes',
          action: 'phone_sent',
          label: apartmentName,
        });
        console.log(`Google Analytics event logged: phone_sent for ${apartmentName}`);

        // Сохраняем состояние разблокировки вместе с названием ЖК
        localStorage.setItem(`isUnlocked-${apartmentName}`, 'true');
      } else {
        console.error("Ошибка при отправке данных:", response.statusText);
      }
    } catch (error) {
      console.error("Ошибка:", error);
    } finally {
      setCurrentStep(4);
      setTimeout(() => {
        if (onComplete) onComplete(true);
      }, 2000);
    }
  };

  const calculateProgress = () => {
    if (currentStep < totalSteps) {
      return (currentStep / totalSteps) * 100;
    }
    if (phone) {
      return 100; // Если номер телефона введен, прогресс 100%
    }
    return ((currentStep - 1) / totalSteps) * 100;
  };

  const renderStep1 = () => (
    <div className="quiz-content">
      <h2 className="quiz-heading">Какую комнатность рассматриваете?</h2>
      <div className="options">
        {["Студия/1-комнатная квартира/апартаменты", "2-комнатная квартира/апартаменты", "3-комнатная квартира/апартаменты", "4-комнатная квартира/апартаменты и более"].map((option) => (
          <button
            key={option}
            onClick={() => handleOptionSelect(option)}
            className={`option-btn ${selectedOption === option ? "selected" : ""}`}
          >
            <span className="radio-circle"></span>
            {option}
          </button>
        ))}
      </div>
    </div>
  );

  const renderStep2 = () => (
    <div className="quiz-content">
      <h2 className="quiz-heading">Выберите способ оплаты</h2>
      <div className="options">
        {["Ипотека", "Трейд-ин", "Рассрочка", "Другое"].map((option) => (
          <button
            key={option}
            onClick={() => handleOptionSelect(option)}
            className={`option-btn ${selectedOption === option ? "selected" : ""}`}
          >
            <span className="radio-circle"></span>
            {option}
          </button>
        ))}
      </div>
    </div>
  );

  const renderStep3 = () => (
    <div className="quiz-content larger-step">
      <h2 className="quiz-heading">Заполните форму и получите персональные предложения</h2>
      <div className="quiz-phone-input-container">
        <label htmlFor="phone" className="quiz-phone-label">Введите телефон</label>
        <div className="quiz-phone-input-wrapper larger-phone-input">
          <InputMask
            mask="+7 (999) 999-99-99"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
            placeholder="+7 (___) ___-__-__"
            className="quiz-phone-input"
          />
        </div>
      </div>
      <button
        className="submit-button"
        onClick={handleSubmit}
        disabled={!phone || !isConsentChecked}
      >
        Получить результаты
      </button>
      <div className="consent-container">
        <input
          type="checkbox"
          id="consent"
          checked={isConsentChecked}
          onChange={(e) => setIsConsentChecked(e.target.checked)}
        />
        <label htmlFor="consent" className="consent-label">Согласие на обработку персональных данных</label>
      </div>
    </div>
  );

  const renderThankYou = () => (
    <div className="thank-you-page">
      <h2 className="quiz-heading">Спасибо!</h2>
      <p>Менеджер скоро свяжется с вами.</p>
    </div>
  );

  return (
    <div className="quiz-container">
      <div className="quiz-header">
        <p>Получите персональные предложения</p>
      </div>
      <div className="quiz-body">
        {currentStep === 1
          ? renderStep1()
          : currentStep === 2
          ? renderStep2()
          : currentStep === 3
          ? renderStep3()
          : renderThankYou()}
      </div>
      {currentStep !== 3 && currentStep <= 3 && (
        <div className="quiz-footer">
          <div className="progress">
            <div className="progress-bar">
              <div className="progress-fill" style={{ width: `${calculateProgress()}%` }}></div>
            </div>
            <span>{Math.round(calculateProgress())}% Готово</span>
          </div>
          <button
            className={`next-button ${!selectedOption && currentStep !== 3 ? "disabled" : ""}`}
            onClick={currentStep === 3 ? handleSubmit : handleNext}
            disabled={currentStep !== 3 && !selectedOption}
          >
            {currentStep === 3 ? "Завершить" : "Далее"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ApartmentQuiz;
