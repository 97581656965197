import React from "react";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Footer from "./components/Footer/Footer";
import Hero from "./components/Hero/Hero";
import Navbar from "./components/navbar/Navbar";
import PrivacyPolicy from "./components/StaticPages/PrivacyPolicy";
import Catalog from "./components/Catalog/Catalog";
import { AreaProvider } from "./components/AreaContext";
import CookieBanner from "./components/CookieBanner/CookieBanner";
import ReactGA from "react-ga4";
import FeaturedProperties from "./components/FeaturedProperties/FeaturedProperties";
import Quiz from "./components/Quiz/Quiz";
import ApartmentPage from "./components/apartmentPage/ApartmentPage";
import FloatingSocials from "./components/FloatingSocials/FloatingSocials";
import DownloadCatalog from "./components/DownloadCatalog/DownloadCatalog";

// Send pageview with a custom path
ReactGA.send({
  hitType: "pageview",
  page: "/",
  title: "Main page",
});

const App = () => {
  return (
    <AreaProvider>
      <Router>
        <FloatingSocials /> {/* Компонент всегда будет виден на всех страницах */}
        <Routes>
          <Route path="/near-river" element={<Quiz type="nearRiver" />} />
          <Route path="/inside-ttk" element={<Quiz type="insideTTK" />} />
          <Route path="/business-class-within-mkad" element={<Quiz type="businessClassWithinMKAD" />} />
          <Route
            path="/"
            element={
              <>
                <Navbar />
                <div className="container">
                  <Hero />
                  <FeaturedProperties />
                  <DownloadCatalog/>
                  <Catalog />
                </div>
                <Footer />
                <CookieBanner />
              </>
            }
          />
          <Route
            path="/privacy-policy"
            element={
              <>
                <Navbar />
                <PrivacyPolicy />
                <Footer />
              </>
            }
          />
          {/* Передаем название ЖК из маршрута */}
          <Route
            path="/:projectName"
            element={
              <>
                <Navbar />
                <ApartmentPage />
                <Footer />
              </>
            }
          />
        </Routes>
      </Router>
    </AreaProvider>
  );
};

export default App;
