import React from 'react';
import './Item.css';

const ItemCard = ({ item, onClick }) => {
  return (
    <div 
      className="grid-item" 
      style={{ backgroundImage: `url(${item.imageUrl})` }}
      onClick={onClick}
    >
      <div className="grid-item-title">
        {item.title}
      </div>
      <div className="hover-overlay">
        <div className="overlay-content">
          <p>Срок сдачи: <span className="highlight">{item.deadline} г.</span></p>
          <p>Район: <span className="highlight">{item.district?.name || "Не указан"}</span></p>
          <p>АО: <span className="highlight">{item.area || "Не указан"}</span></p>
        </div>
      </div>
    </div>
  );
};

export default ItemCard;
