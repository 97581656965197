import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./ApartmentPage.css";
import LockIcon from "../../assets/appartment-images/icons/lock.png";
import PlayIcon from "../../assets/modal/play.png";
import ApartmentQuiz from "./apartmentQuiz/ApartmentQuiz";
import MetroIcon from "../../assets/hero/metro_logo.png";
import ReactGA from "react-ga4"; // Подключаем Google Analytics

const ApartmentPage = () => {
  const { projectName } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [showQuiz, setShowQuiz] = useState(false);
  const [isUnlocked, setIsUnlocked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  // Функция для отслеживания целей
  const logGoals = (apartmentName) => {
    // Проверка доступности Yandex.Metrika
    if (typeof window.ym === "function") {
      window.ym(98664452, "reachGoal", "show_quiz");
      console.log(`Yandex goal reached: quiz_start for ${apartmentName}`);
    } else {
      console.error("Yandex.Metrika is not available.");
    }

    // Логируем событие для GA4
    ReactGA.event({
      category: 'Residential complexes',
      action: 'show_quiz',
      label: apartmentName,
    });
    console.log(`Google Analytics event logged: show_quiz for ${apartmentName}`);
  };

  useEffect(() => {
    const savedState = localStorage.getItem(`isUnlocked-${projectName}`) === "true";
    setIsUnlocked(savedState);
  }, [projectName]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`https://muraestate.ru/api/realestate/${projectName}`);
        if (!response.ok) {
          throw new Error("Ошибка загрузки данных с сервера");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Ошибка:", error.message);
        setData({
          title: "ЖК Southport",
          price: "15 000 000 ₽",
          area: "85 м²",
          metroStations: [
            { name: "ЗИЛ", distance: 10, color: "#FF0000" },
            { name: "Автозаводская", distance: 15, color: "#00FF00" },
          ],
          tags: ["Квартиры в Москве", "Рядом с метро", "Новостройка", "Комфорт-класс"],
          imageUrl:
            "https://storage.yandexcloud.net/bytehub/real-estate/images/%D0%B6%D0%BA%20%D0%BA%D0%BE%D0%BC%D0%BF%D0%BB%D0%B5%D0%BA%D1%81%D1%8B/card-background%20%D0%BE%D0%B1%D0%BD%D0%BE%D1%81%D0%B0%D0%BD%D0%B8%D0%B5/shagal.jpg",
          pdfUrl: null,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [projectName]);

  return (
    <>
      {showQuiz && (
        <div className="quiz-overlay">
          <ApartmentQuiz
            onComplete={(unlockStatus) => {
              setShowQuiz(false);
              setIsUnlocked(unlockStatus);
            }}
            apartmentName={projectName}
          />
        </div>
      )}

      {loading ? (
        <p className="loading-message">Загрузка...</p>
      ) : (
        <div className="apartment-page">
          <div className="first-block">
            <div className="apartment-info-block">
              <h1 className="apartment-title">{data.title}</h1>
              <div className="apartment-stats">
                <div className="detail">
                  <span className="detail-title">Застройщик:</span>
                  <span className="detail-value">{data.developer?.name || "—"}</span>
                </div>
                <div className="detail">
                  <span className="detail-title">Цена м²:</span>
                  <span className="detail-value">
                    от {data.priceFrom ? `${data.priceFrom} тыс. ₽` : "—"}
                  </span>
                </div>
              </div>
              <div className="apartment-details">
                <div className="detail">
                  <span className="detail-title">Административный округ:</span>
                  <span className="detail-value">{data.area || "—"}</span>
                </div>
                <div className="detail">
                  <span className="detail-title">Район:</span>
                  <span className="detail-value">{data.district?.name || "—"}</span>
                </div>
              </div>
              <div className="apartment-tags">
                {data.tags && Array.isArray(data.tags) && data.tags.length > 0 ? (
                  data.tags.map((tag, index) => (
                    <button key={index} className="tag-button">
                      {tag}
                    </button>
                  ))
                ) : (
                  <div className="empty-tags-line"></div>
                )}
              </div>
              {data.metroStations && data.metroStations.length > 0 && (
                <div className="metro-info">
                  {data.metroStations.map((station, index) => (
                    <div key={index} className="metro-station">
                      <img src={MetroIcon} alt="Metro Icon" className="metro-icon" />
                      <span className="metro-name" style={{ color: station.color }}>
                        {station.name}
                      </span>
                      <span className="metro-distance">{station.distance} мин пешком</span>
                    </div>
                  ))}
                </div>
              )}

            </div>
            {data.imageUrl && (
              <img src={data.imageUrl} alt={data.title} className="apartment-image-top" />
            )}
          </div>

          <div className="quiz-and-media-container">
            <div className="quiz-section">
              <p>{data.pdfUrl ? "Ответьте на 2 вопроса, и получите видеопрезетацию данного жилого комплекса." : "Ответьте на 2 вопроса и получите полную pdf-презентацию лучших предложений новых домов Москвы."}</p>
              <button
                className={`quiz-button ${isUnlocked ? "disabled" : ""}`}
                onClick={() => {
                  if (!isUnlocked) {
                    setShowQuiz(true);
                    logGoals(projectName); // Логируем событие
                  }
                }}
                disabled={isUnlocked}
              >
                Пройти квиз
              </button>
            </div>

            {data.pdfUrl ? (
              <div className="media-container">
                <div className="video-container" onClick={() => isUnlocked && setShowModal(true)}>
                  {isUnlocked ? (
                    <div className="video-unlocked">
                      <img src={data.imageUrl} alt="Видеопрезентация" className="video-bg" />
                      <img src={PlayIcon} alt="Play" className="play-icon" />
                    </div>
                  ) : (
                    <div className="overlay">
                      <img src={LockIcon} alt="Заблокировано" />
                      <p>Видео закрыто</p>
                    </div>
                  )}
                </div>
                <div className="pdf-container">
                  {isUnlocked ? (
                    <div className="unlocked-content">
                      <a
                        href={data.pdfUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="download-presentation"
                      >
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/724/724715.png"
                          alt="Download Icon"
                          className="download-icon"
                        />
                        <span>Скачать презентацию</span>
                      </a>
                    </div>
                  ) : (
                    <div className="overlay">
                    <img src={LockIcon} alt="Заблокировано" />
                    <p>Презентация закрыта</p>
                  </div>
                  )}
                </div>
              </div>
            ) : (
              <div className="single-media-container">
                {isUnlocked ? (
                  <div className="unlocked-content">
                    <a
                      href="https://storage.yandexcloud.net/bytehub/real-estate/pdf/presentation_business_class.pdf"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="download-presentation"
                    >
                      <img
                        src="https://cdn-icons-png.flaticon.com/512/724/724715.png"
                        alt="Download Icon"
                        className="download-icon"
                      />
                      <span>Скачать презентацию</span>
                    </a>
                  </div>
                ) : (
                  <div className="overlay">
                    <img src={LockIcon} alt="Заблокировано" />
                    <p>Презентация закрыта</p>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}

      {showModal && (
        <div className="modal-overlay" onClick={() => setShowModal(false)}>
          <video
            src="https://storage.yandexcloud.net/bytehub/real-estate/video/Presentations/severniy-port.webm"
            className="modal-video"
            controls
            autoPlay
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </>
  );
};

export default ApartmentPage;
