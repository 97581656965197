export const AreaEnum = {
  CENTER: 'Центральный АО',
  WEST: 'Западный АО',
  NORTH_EAST: 'Северо-Восточный АО',
  NORTH_WEST: 'Северо-Западный АО',
  NORTH: 'Северный АО',
  SOUTH: 'Южный АО',
  SOUTH_WEST: 'Юго-Западный АО',
  SOUTH_EAST: 'Юго-Восточный АО',
  EAST: 'Восточный АО',
};
