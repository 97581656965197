import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Quiz.css";
import NearRiver from "../../assets/quiz/near-river.jpg";
import InsideTTK from "../../assets/quiz/inside-ttk.jpg";
import BusinessClassWithinMKAD from "../../assets/quiz/comfort-within-mkad.jpg";
import ApartmentQuiz from "../../components/apartmentPage/apartmentQuiz/ApartmentQuiz";
import ReactGA from "react-ga4"; // Подключаем Google Analytics

const quizData = {
  nearRiver: {
    title: "Новостройки у Москвы реки",
    description: "Ответьте на 2 вопроса, и мы подберем квартиру с видом на реку, соответствующую вашим требованиям.",
    image: NearRiver,
    url: "near-river",
  },
  insideTTK: {
    title: "Новостройки в пределах ТТК",
    description: "Ответьте на 2 вопроса и мы подберем для вас лучшие предложения, которые идеально подойдут для вашего стиля жизни.",
    image: InsideTTK,
    url: "inside-ttk",
  },
  businessClassWithinMKAD: {
    title: "Новые дома бизнес-класса в пределах МКАД",
    description: "Ответьте на 2 вопроса и мы подберем для вас лучшие предложения бизнес-класса Москвы",
    image: BusinessClassWithinMKAD,
    url: "business-class-within-mkad",
  },
};

const Quiz = ({ type }) => {
  const [isQuizStarted, setIsQuizStarted] = useState(false);
  const navigate = useNavigate();

  // Логируем успешное событие в Yandex Метрике и Google Analytics
  const logConversion = (url) => {
    // Проверка доступности Yandex.Metrika
    if (typeof window.ym === "function") {
      window.ym(98664452, "reachGoal", "go_quiz");
      console.log(`Yandex goal reached: go_quiz for ${url}`);
    } else {
      console.error("Yandex.Metrika is not available.");
    }

    // Логирование в Google Analytics
    ReactGA.event({
      category: "Residential complexes",
      action: "go_quiz",
      label: url,
    });
    console.log(`Google Analytics event logged: go_quiz for ${url}`);
  };

  const handleStart = () => {
    setIsQuizStarted(true);
    const quizUrl = quizData[type]?.url || "near-river"; // По умолчанию "near-river"
    logConversion(quizUrl); // Логируем конверсию
  };

  const handleQuizComplete = () => {
    navigate("/");
  };

  const handleGoHome = () => {
    navigate("/");
  };

  const quizContent = quizData[type] || quizData.nearRiver;

  return (
    <div className={`quiz-container ${isQuizStarted ? "quiz-active" : ""}`}>
      <div
        className="quiz-background"
        style={{
          backgroundImage: `url(${quizContent.image})`,
        }}
      ></div>
      {!isQuizStarted ? (
        <div className="gradient-overlay">
          <div className="quiz-content-wrapper">
            <div className="quiz-start-content">
              <h1>{quizContent.title}</h1>
              <p>{quizContent.description}</p>
              <button onClick={handleStart} className="start-button">
                Оставить заявку
              </button>
            </div>
          </div>
          <button onClick={handleGoHome} className="home-button">
            На главную
          </button>
        </div>
      ) : (
        <ApartmentQuiz onComplete={handleQuizComplete} />
      )}
    </div>
  );
};

export default Quiz;
