import React, { useState } from "react";
import "./FloatingSocials.css";
import { FaWhatsapp, FaTelegramPlane, FaVk, FaPhoneAlt } from "react-icons/fa";
import ReactGA from "react-ga4"; // Подключаем Google Analytics

const FloatingSocials = () => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleMenu = () => {
    setIsExpanded(!isExpanded);
  };

  const logGoal = (platform) => {
    if (platform === "telegram") {
      if (window.ym) {
        window.ym(98664452, "reachGoal", "go_tg");
        console.log("Yandex goal reached: go_tg");
      }
      ReactGA.event({
        category: "Social Clicks",
        action: "go_tg",
        label: "Moved to TG",
      });
      console.log("Google Analytics event logged: go_tg (Moved to TG)");
    } else if (platform === "whatsapp") {
      if (window.ym) {
        window.ym(98664452, "reachGoal", "go_wa");
        console.log("Yandex goal reached: go_wa");
      }
      ReactGA.event({
        category: "Social Clicks",
        action: "go_wa",
        label: "Moved to WA",
      });
      console.log("Google Analytics event logged: go_wa (Moved to WA)");
    }
  };

  return (
    <div className="floating-socials">
      {/* Кнопка для открытия/закрытия меню */}
      <button className={`floating-button ${isExpanded ? "expanded" : ""}`} onClick={toggleMenu}>
        {isExpanded ? (
          "✖"
        ) : (
          <>
            Пишите, мы онлайн
            <span className="blinking-dot"></span>
          </>
        )}
      </button>

      {/* Список социальных иконок */}
      {isExpanded && (
        <div className="socials-menu">
          <a
            href="https://wa.me/message/5RN54NMSW44HN1?src=website"
            target="_blank"
            rel="noopener noreferrer"
            className="social-item"
            onClick={() => logGoal("whatsapp")}
          >
            <FaWhatsapp className="social-icon whatsapp" />
            <span>WhatsApp</span>
          </a>

          <a
            href="https://t.me/MURAESTATE_bot"
            target="_blank"
            rel="noopener noreferrer"
            className="social-item"
            onClick={() => logGoal("telegram")}
          >
            <FaTelegramPlane className="social-icon telegram" />
            <span>Telegram</span>
          </a>

          <a href="tel:+74951086977" target="_blank" rel="noopener noreferrer" className="social-item">
            <FaPhoneAlt className="social-icon phone" />
            <span>Позвонить</span>
          </a>
        </div>
      )}
    </div>
  );
};

export default FloatingSocials;
