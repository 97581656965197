import React, { useState } from "react";
import "./DownloadCatalog.css";
import ReactGA from "react-ga4";
import InputMask from 'react-input-mask';
import backgroundImage from "../../assets/quiz/near-river.jpg";
import catalogImage from "../../assets/catalog_preview.png";
import { FaPhone } from 'react-icons/fa';

const DownloadCatalog = () => {
  const [phone, setPhone] = useState("");
  const [error, setError] = useState("");
  const [isAccepted, setIsAccepted] = useState(false);

  // Функция для получения clientId из Google Analytics
  const getClientId = () => {
    return new Promise((resolve, reject) => {
      if (window.gtag) {
        window.gtag("get", "G-XXXXXXXXXX", "client_id", (clientId) => {
          if (clientId) resolve(clientId);
          else reject("Client ID not available");
        });
      } else {
        reject("gtag.js not found");
      }
    });
  };

  const containerStyle = {
    background: `linear-gradient(rgba(20, 20, 39, 0.85), rgba(20, 20, 39, 0.95)), url(${backgroundImage})`,
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  };

  const handlePhoneChange = (e) => {
    const value = e.target.value;
    setPhone(value);
    setError("");
  };

  const handleSubmit = async () => {
    if (!isAccepted) {
      setError("Необходимо принять условия политики конфиденциальности");
      return;
    }

    const phoneDigits = phone.replace(/\D/g, '');
    
    if (phoneDigits.length !== 11 || !phoneDigits.startsWith('7')) {
      setError("Введите корректный номер телефона");
      return;
    }

    try {
      // Получаем clientId
      const clientId = await getClientId();

      const data = {
        phoneNumber: phone,
        clientId: clientId,
        additionalInfo: "Скачивание каталога новостроек"
      };

      const response = await fetch("https://muraestate.ru/api/amocrm", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        console.log("Данные успешно отправлены:", data);

        // Яндекс Метрика
        if (typeof window.ym === "function") {
          window.ym(98664452, "reachGoal", "catalog_download");
          console.log("Yandex goal reached: catalog_download");
        }

        // Google Analytics
        ReactGA.event({
          category: "Downloads",
          action: "catalog_download",
          label: "Catalog PDF",
        });

        // Открываем PDF в новом окне
        window.open(
          "https://storage.yandexcloud.net/bytehub/real-estate/pdf/presentation_business_class.pdf",
          "_blank"
        );
      } else {
        console.error("Ошибка при отправке данных:", response.statusText);
        setError("Произошла ошибка при отправке данных. Пожалуйста, попробуйте позже.");
      }
    } catch (error) {
      console.error("Ошибка:", error);
      setError("Произошла ошибка. Пожалуйста, попробуйте позже.");
    }
  };

  return (
    <div className="download-section-wrapper">
      <div className="download-section-container">
        <div className="download-catalog-container" style={containerStyle}>
          <div className="download-catalog-left">
            <h2>ДЛЯ ЖИЗНИ</h2>
            <h2>И ИНВЕСТИЦИЙ</h2>
            <div className="catalog-description">
              Получите PDF-каталог новых домов бизнес и премиум-класса в Москве
            </div>
          </div>

          <div className="download-catalog-right">
            <div className="catalog-title">КАТАЛОГ НОВОСТРОЕК МОСКВЫ</div>
            <div className="phone-input-container">
              <div className="catalog-input-icon">
                <FaPhone />
                <span className="country-code">RU</span>
              </div>
              <InputMask
                mask="+7 (999) 999-99-99"
                value={phone}
                onChange={handlePhoneChange}
                placeholder="+7 (___) ___-__-__"
                className={error ? "error" : ""}
                beforeMaskedValueChange={({ value, selection }) => {
                  if (value === '+') {
                    return {
                      value: '+7',
                      selection: { start: 2, end: 2 }
                    };
                  }
                  return { value, selection };
                }}
              />
            </div>
            <div className="privacy-policy-container">
              <label className="privacy-checkbox">
                <input
                  type="checkbox"
                  checked={isAccepted}
                  onChange={(e) => {
                    setIsAccepted(e.target.checked);
                    setError("");
                  }}
                />
                <span className="checkmark"></span>
                <span className="privacy-text">
                  Я согласен с{" "}
                  <a 
                    href="/privacy-policy" 
                    target="_blank" 
                    rel="noopener noreferrer"
                  >
                    политикой конфиденциальности
                  </a>
                </span>
              </label>
            </div>
            {error && <span className="error-message">{error}</span>}
            <button 
              className={`download-button ${!isAccepted ? 'disabled' : ''}`} 
              onClick={handleSubmit}
              disabled={!isAccepted}
            >
              Получить
            </button>
          </div>

          <div className="catalog-preview">
            <img src={catalogImage} alt="Каталог новостроек" className="catalog-image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DownloadCatalog;