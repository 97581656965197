import React, { useState, useEffect } from 'react';
import './CardsSet.css';
import ItemCard from './ItemCard/Item.jsx';
import ReactGA from "react-ga4";

const CardSet = ({ filteredCards }) => {
  const [visibleCards, setVisibleCards] = useState(18); // По умолчанию 18 карточек
  const [loading, setLoading] = useState(true); // состояние загрузки
  const [isMobile, setIsMobile] = useState(false); // состояние для проверки мобильных устройств

  useEffect(() => {
    setTimeout(() => {
      setLoading(false); // Устанавливаем загрузку как завершенную
    }, 2000);

    const updateVisibleCards = () => {
      const isNowMobile = window.innerWidth <= 768;
      if (isNowMobile !== isMobile) {
        setIsMobile(isNowMobile);
        setVisibleCards(isNowMobile ? 6 : 18);
      }
    };

    window.addEventListener('resize', updateVisibleCards);
    updateVisibleCards();

    return () => {
      window.removeEventListener('resize', updateVisibleCards);
    };
  }, [isMobile]);

  // Функция транслитерации
  const transliterate = (text) => {
    const ruToLatMap = {
      а: "a", б: "b", в: "v", г: "g", д: "d", е: "e", ё: "yo", ж: "zh", з: "z", и: "i",
      й: "y", к: "k", л: "l", м: "m", н: "n", о: "o", п: "p", р: "r", с: "s", т: "t",
      у: "u", ф: "f", х: "kh", ц: "ts", ч: "ch", ш: "sh", щ: "shch", ы: "y", э: "e",
      ю: "yu", я: "ya", "ь": "", "ъ": ""
    };

    return text
      .toLowerCase()
      .split("")
      .map((char) => ruToLatMap[char] || char)
      .join("");
  };

  const handleCardClick = (item) => {
    const sanitizedTitle = item.title
      .toLowerCase()
      .replace(/[^a-z0-9а-яё\s-]/gi, '') // Убираем все кроме букв, цифр, пробелов и дефисов
      .trim();
    
    const transliteratedTitle = transliterate(sanitizedTitle);
    
    // Заменяем пробелы и множественные дефисы на один дефис
    const formattedTitle = transliteratedTitle.replace(/\s+/g, '-').replace(/-+/g, '-');

    // Google Analytics
    ReactGA.event({
      category: "Residential complexes",
      action: "Page opened",
      label: item.title
    });

    console.log("Redirecting to: /" + formattedTitle);
    window.open(`${window.location.origin}/${formattedTitle}`, '_blank');
  };

  const loadMoreCards = () => {
    setVisibleCards((prevVisibleCards) => {
      const increment = window.innerWidth <= 768 ? 9 : 18; // 18 карточек для больших экранов, 9 для мобильных
      const newVisibleCards = prevVisibleCards + increment;
      return Math.min(newVisibleCards, filteredCards.length);
    });
  };

  return (
    <section>
      <div className="grid-container">
        {loading ? (
          Array.from({ length: isMobile ? 6 : 18 }).map((_, index) => (
            <div key={index} className="skeleton-card"></div>
          ))
        ) : (
          filteredCards.slice(0, visibleCards).map((item) => (
            <ItemCard
              key={item.id || item.title + Math.random()}
              item={item}
              onClick={() => handleCardClick(item)}
            />
          ))
        )}
      </div>
      {visibleCards < filteredCards.length && !loading && (
        <div className="load-more-container">
          <button className="load-more-button" onClick={loadMoreCards}>
            Показать ещё
          </button>
        </div>
      )}
    </section>
  );
};

export default CardSet;
